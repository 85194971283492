import React from 'react'
import { FaShoppingCart } from 'react-icons/fa'

export default function ButtonShowNow() {
    return (
        <a
            href='http://e6c096-9e.myshopify.com/'
            target='_blank'
            rel='noreferrer'
            type="button"
            class="bg-2 text-center w-48 rounded-2xl h-14 relative font-sans text-white group flex items-center justify-center "
        >
            <div
                class="bg-white rounded-xl h-12 w-1/4 flex items-center text-xl justify-center absolute left-1 top-[4px] group-hover:w-[184px] z-10 duration-500 text-blue-500"
            >
                <FaShoppingCart />
            </div>
            <p class="translate-x-2 text-lg font-bold ml-4">Shop Now</p>
        </a>
    )
}
