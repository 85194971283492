import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../context/context'
// import place from '../../assets/image/placeholder.png'
import CardProductBusqueda from './CardProductBusqueda'
const Busqueda = () => {
    const { rpdata } = useContext(GlobalDataContext)

    const [cardProduct, setCardProduct] = useState('')

    const [search, setSearch] = useState('')

    //funcion de busqeda



    const resultFilter = !search ? rpdata?.works : rpdata?.works?.filter((datos) => datos?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))


    const handleSelectChange = (e) => {
        setCardProduct(e.target.value)
        setSearch('')

    }

    return (
        <div className='h-full w-4/5 mx-auto '>

            <div>
                <div className="flex flex-col-reverse md:flex-row md:gap-0 gap-5 py-20">

                    <select className={`flex-shrink-0 z-10 py-2.5 px-4 text-sm font-medium text-start text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100  ${!search ? 'block w-full' : 'hidden md:inline-flex'}`} onChange={handleSelectChange}>
                        <option selected disabled>Products</option>
                        {
                            rpdata?.dbServices?.map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>{item.name}</option>
                                )
                            })
                        }
                    </select>


                </div>
                
            </div>

            <div className='flex h-full'>
                <div className='w-full h-full'>
                    <div className='w-full h-full flex justify-center flex-wrap gap-10'>
                        {


                            cardProduct === '' ?
                                resultFilter?.map((item, index) => {
                                    return (

                                        <>
                                            <CardProductBusqueda
                                                styleCard={"w-[350px] text-center shadow-lg pb-5 rounded-lg p-4"}
                                                key={index}
                                                imagen={item.gallery?.[0]}
                                                name={
                                                    rpdata?.dbServices?.map((service) => (
                                                        service.name === item.service ? service.name : null
                                                    ))
                                                }
                                                price={item.name}
                                                urlBtn={item.service.replaceAll(/[\^*@!"#$%&/()=?¡!¿' ]/g, "-").toLowerCase()}
                                            />
                                        </>


                                    )
                                })
                                :
                                <div className='flex flex-wrap gap-3 p-5'>
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        rpdata?.works?.map((item, index) => {
                                            const expert = item?.service;
                                            switch (cardProduct) {
                                                case `${expert}`:
                                                    return (

                                                        <CardProductBusqueda
                                                        StyleiBlock1={"h-[250px] w-full md:w-[30%] pb-5"}
                                                        StyleiBlock2={"w-full md:w-[70%] text-start"}
                                                        styleCard={"w-full flex flex-col md:flex-row text-center shadow-lg pb-5 rounded-lg p-4"}
                                                            key={index}
                                                            imagen={item.gallery?.[0]}
                                                            name={item.name}
                                                            price={item.description}
                                                            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-", "")}
                                                        />
                                                    )
                                                default:
                                                // do nothing
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Busqueda
