import React from 'react'
import ButtonShowNow from '../global/boton/ButtonShowNow'


const CardProductBusqueda = ({ imagen, name, price, urlBtn, styleCard, StyleiBlock1, StyleiBlock2 }) => {
    return (
        <>
            <div className={styleCard}>
                <div className={StyleiBlock1}>
                    <img src={imagen} alt="colores" className=' w-full h-full rounded-t-lg object-contain' />
                </div>
                <div className={StyleiBlock2}>
                    <p>{name}</p>
                    <p>{price}</p>

                </div>
                <div className='flex justify-center'>
                    <ButtonShowNow />
                </div>

            </div>

        </>
    )
}

export default CardProductBusqueda