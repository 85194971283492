import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import Busqueda from "../components/Services/Busqueda";


function Catalog() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="About">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Our Products"
                    Subheader="Our Products"
                    bgimg={`${rpdata?.gallery?.[100]}`}
                />

                <Busqueda />
            </div>
        </BaseLayout>
    );
}

export default Catalog;
